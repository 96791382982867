import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//componentes
import "./planillaI.css";
import "./planillam.css";
import Alert from "../../modal/alert";

class planillaI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ifechamedi: [],
      nalumno: [],
      igrado: [],
      ianoEscolar: [],
      anoEscolar: "",
      mensajealerta: false,
      listaOn: false,
      identificacionp1: "",
      tbusqueda: "0",
      verplanillaOn: false,
      botonOn: true,
      botonNanaOn: false,
      botonChoferOn: false,
      botonOtroOn: false,
      mensajealerta: false,
      nombre: "",
      apellido: "",
      identificacion: "",
      nombremadre: "",
      apellidomadre: "",
      identificacionmadre: "",
      ocupacionmadre: "",
      ltrabajomadre: "",
      telefonotrabajomadre: "",
      celularmadre: "",
      domiciliomadre: "",
      telecasamadre: "",
      correomadre: "",
      nacionalidadmadre: "",
      nombrepadre: "",
      apellidopadre: "",
      identificacionpadre: "",
      ocupacionpadre: "",
      ltrabajopadre: "",
      telefonotrabajopadre: "",
      celularpadre: "",
      domiciliopadre: "",
      telecasapadre: "",
      correopadre: "",
      nacionalidadpadre: "",
      fnacimiento: "",
      edad: "",
      telefonos: "",
      nacionalidad: "",
      lnacimiento: "",
      domicilio: "",
      personasviven: "",
      fechainicio: "",
      turno: "",
      transporte: "",
      personaencargada: "",
      telecontacto: "",
      madre: "",
      padre: "",
      nana: "",
      chofer: "",
      otro: "",
      autorizado1: "",
      autorizado2: "",
      autorizado3: "",
      autorizado4: "",
      autorizado5: "",
      celularperautori1: "",
      celularperautori2: "",
      celularperautori3: "",
      celularperautori4: "",
      celularperautori5: "",
      correoperautori1: "",
      correoperautori2: "",
      correoperautori3: "",
      nombrenoauto1: "",
      nombrenoauto2: "",
      nombrenoauto3: "",
      nombrenoauto4: "",
      nombrenoauto5: "",
      relacionnoauto1: "",
      relacionnoauto2: "",
      relacionnoauto3: "",
      nombreemergencia: "",
      relacionemergencia: "",
      tlfemergencia: "",
      antecedentes: "",
      tratamiento: "",
      tiempoembarazo: "",
      idiomamaterno: "",
      conductapositiva: "",
      conductanegativa: "",
      nombreescuela: "",
      rangotiempo: "",
      atendidopor: "",
      tiempocompartidomadre: "",
      tiempocompartidopadre: "",
      padeceenfermedad: "",
      hospitalizado: "",
      ocaciones: "",
      operadocausa: "",
      especifique: "",
      especifiquemedicamento: "",
      peso: "",
      talla: "",
      sangre: "",
      otroalergica: "",
      opantecedentes: "",
      btnemOn: false,
      btnem2On: false,
      tlfemergencia2: "",
      relacionemergencia2: "",
      nombreemergencia2: "",
      tlfemergencia3: "",
      relacionemergencia3: "",
      nombreemergencia3: "",
      nombrerecibo: "",
      cedularecibo: "",
      opcedula: "Cedula",
      opsexo: "MASCULINO",
      opcedulaM: "Cedula",
      opcedulaP: "Cedula",
      opnoautorizado: "NO",
      opantecedentes: "NO",
      optipoparto: "NORMAL",
      optratamiento: "NO",
      opexperiencia: "SI",
      ophospitalizado: "NO",
      opoperado: "NO",
      opcondicion: "NO",
      opmedicamento: "NO",
      opvacunacion: "COMPLETA",
      oprepresentante: "MADRE",
      opredessociales: "NO",
      opevangelio: "NO",
      icursos: [],
      cursos: "",
      penicilina: "",
      mariscos: "",
      yodo: "",
      merthiolate: "",
      aspirina: "",
      citrico: "",
      laptosa: "",
      otroa: "",
      ninguna: "",
      convulsiones: "",
      asma: "",
      desmayo: "",
      diabetes: "",
      rubeola: "",
      papera: "",
      sarampion: "",
      varicela: "",
      tosferina: "",
      hepatitis: "",
      amebiasis: "",
      ningunae: "",
      btnAceptarOn: false,
      btnnoauto1On: "NO",
      btnnoauto2On: "NO",
      otroenfermedad: "",
      otroen: "",
      inicioOn: true,
      mensaje: "",
      nivel: "",
      status: "",
      idplanillaM: "",
      eliplan: false,
      grados: "",
    };
  }

  limpiar = () => {
    this.setState({
      ifechamedi: [],
      nalumno: [],
      igrado: [],
      anoEscolar: "",
      mensajealerta: false,
      listaOn: false,
      identificacionp1: "",
      tbusqueda: "0",
      verplanillaOn: false,
      botonOn: true,
      botonNanaOn: false,
      botonChoferOn: false,
      botonOtroOn: false,
      mensajealerta: false,
      nombre: "",
      apellido: "",
      identificacion: "",
      nombremadre: "",
      apellidomadre: "",
      identificacionmadre: "",
      ocupacionmadre: "",
      ltrabajomadre: "",
      telefonotrabajomadre: "",
      celularmadre: "",
      domiciliomadre: "",
      telecasamadre: "",
      correomadre: "",
      nacionalidadmadre: "",
      nombrepadre: "",
      apellidopadre: "",
      identificacionpadre: "",
      ocupacionpadre: "",
      ltrabajopadre: "",
      telefonotrabajopadre: "",
      celularpadre: "",
      domiciliopadre: "",
      telecasapadre: "",
      correopadre: "",
      nacionalidadpadre: "",
      fnacimiento: "",
      edad: "",
      telefonos: "",
      nacionalidad: "",
      lnacimiento: "",
      domicilio: "",
      personasviven: "",
      fechainicio: "",
      turno: "",
      transporte: "",
      personaencargada: "",
      telecontacto: "",
      madre: "",
      padre: "",
      nana: "",
      chofer: "",
      otro: "",
      autorizado1: "",
      autorizado2: "",
      autorizado3: "",
      autorizado4: "",
      autorizado5: "",
      celularperautori1: "",
      celularperautori2: "",
      celularperautori3: "",
      celularperautori4: "",
      celularperautori5: "",
      correoperautori1: "",
      correoperautori2: "",
      correoperautori3: "",
      nombrenoauto1: "",
      nombrenoauto2: "",
      nombrenoauto3: "",
      nombrenoauto4: "",
      nombrenoauto5: "",
      relacionnoauto1: "",
      relacionnoauto2: "",
      relacionnoauto3: "",
      nombreemergencia: "",
      relacionemergencia: "",
      tlfemergencia: "",
      antecedentes: "",
      tratamiento: "",
      tiempoembarazo: "",
      idiomamaterno: "",
      conductapositiva: "",
      conductanegativa: "",
      nombreescuela: "",
      rangotiempo: "",
      atendidopor: "",
      tiempocompartidomadre: "",
      tiempocompartidopadre: "",
      padeceenfermedad: "",
      hospitalizado: "",
      ocaciones: "",
      operadocausa: "",
      especifique: "",
      especifiquemedicamento: "",
      peso: "",
      talla: "",
      sangre: "",
      otroalergica: "",
      opantecedentes: "",
      btnemOn: false,
      btnem2On: false,
      tlfemergencia2: "",
      relacionemergencia2: "",
      nombreemergencia2: "",
      tlfemergencia3: "",
      relacionemergencia3: "",
      nombreemergencia3: "",
      nombrerecibo: "",
      cedularecibo: "",
      opcedula: "Cedula",
      opsexo: "MASCULINO",
      opcedulaM: "Cedula",
      opcedulaP: "Cedula",
      opnoautorizado: "NO",
      opantecedentes: "NO",
      optipoparto: "NORMAL",
      optratamiento: "NO",
      opexperiencia: "SI",
      ophospitalizado: "NO",
      opoperado: "NO",
      opcondicion: "NO",
      opmedicamento: "NO",
      opvacunacion: "COMPLETA",
      oprepresentante: "MADRE",
      opredessociales: "NO",
      opevangelio: "NO",
      cursos: "",
      penicilina: "",
      mariscos: "",
      yodo: "",
      merthiolate: "",
      aspirina: "",
      citrico: "",
      laptosa: "",
      otroa: "",
      ninguna: "",
      convulsiones: "",
      asma: "",
      desmayo: "",
      diabetes: "",
      rubeola: "",
      papera: "",
      sarampion: "",
      varicela: "",
      tosferina: "",
      hepatitis: "",
      amebiasis: "",
      ningunae: "",
      btnAceptarOn: false,
      btnnoauto1On: "NO",
      btnnoauto2On: "NO",
      otroenfermedad: "",
      otroen: "",
      mensaje: "",
      nivel: "",
      status: "",
      idplanillaM: "",
      eliplan: false,
      grados: "",
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      if (e.target.name == "nana") {
        this.setState({
          botonNanaOn: !this.state.botonNanaOn,
        });
      }
      if (e.target.name == "chofer") {
        this.setState({
          botonChoferOn: !this.state.botonChoferOn,
        });
      }
      if (e.target.name == "otro") {
        this.setState({
          botonOtroOn: !this.state.botonOtroOn,
        });
      }
      this.setState({
        [e.target.name]: true,
      });
    } else {
      if (e.target.name == "nana") {
        this.setState({
          botonNanaOn: !this.state.botonNanaOn,
        });
      }
      if (e.target.name == "chofer") {
        this.setState({
          botonChoferOn: !this.state.botonChoferOn,
        });
      }
      if (e.target.name == "otro") {
        this.setState({
          botonOtroOn: !this.state.botonOtroOn,
        });
      }
      this.setState({
        [e.target.name]: false,
      });
    }
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscargrado(e.target.value);
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
    //console.log('cerrar')
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      eliplan: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  eliminar1 = () => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Busqueda de Planillas",
      cuerpoM: "Desea Realmente Eliminar el Expediente...",
      eliplan: true,
    });
  };

  tituloInicio = () => {
    if (this.state.inicioOn) {
      this.setState({
        inicioOn: false,
      });
    } else {
      this.setState({
        inicioOn: true,
      });
    }
  };

  cargarDatos = (e) => {
    //console.log(e.data)
    this.setState({
      nombre: e.data.variable1,
      apellido: e.data.variable2,
      opcedula: e.data.variable3,
      identificacion: e.data.variable4,
      edad: e.data.variable5,
      opsexo: e.data.variable6,
      lnacimiento: e.data.variable7,
      fnacimiento: e.data.variable8,
      nacionalidad: e.data.variable9,
      telefonos: e.data.variable10,
      domicilio: e.data.variable11,
      personasviven: e.data.variable12,
      nombremadre: e.data.variable13,
      apellidomadre: e.data.variable14,
      opcedulaM: e.data.variable15,
      identificacionmadre: e.data.variable16,
      ocupacionmadre: e.data.variable17,
      ltrabajomadre: e.data.variable18,
      telefonotrabajomadre: e.data.variable19,
      celularmadre: e.data.variable20,
      domiciliomadre: e.data.variable21,
      telecasamadre: e.data.variable22,
      correomadre: e.data.variable23,
      nacionalidadmadre: e.data.variable24,
      nombrepadre: e.data.variable25,
      apellidopadre: e.data.variable26,
      opcedulaP: e.data.variable27,
      identificacionpadre: e.data.variable28,
      ocupacionpadre: e.data.variable29,
      ltrabajopadre: e.data.variable30,
      telefonotrabajopadre: e.data.variable31,
      celularpadre: e.data.variable32,
      domiciliopadre: e.data.variable33,
      telecasapadre: e.data.variable34,
      correopadre: e.data.variable35,
      nacionalidadpadre: e.data.variable36,
      fechainicio: e.data.variable37,
      cursos: e.data.variable39,
      grados: e.data.variable40,
      transporte: e.data.variable41,
      personaencargada: e.data.variable42,
      telecontacto: e.data.variable43,
      madre: e.data.variable44,
      padre: e.data.variable45,
      nana: e.data.variable46,
      chofer: e.data.variable47,
      otro: e.data.variable48,

      autorizado1: e.data.variable49,
      celularperautori1: e.data.variable50,
      correoperautori1: e.data.variable51,
      autorizado2: e.data.variable52,
      celularperautori2: e.data.variable53,
      correoperautori2: e.data.variable54,
      autorizado3: e.data.variable55,
      celularperautori3: e.data.variable56,
      correoperautori3: e.data.variable57,
      opnoautorizado: e.data.variable58,
      nombrenoauto1: e.data.variable59,
      relacionnoauto1: e.data.variable60,
      nombrenoauto2: e.data.variable61,
      relacionnoauto2: e.data.variable62,
      nombrenoauto3: e.data.variable63,
      relacionnoauto3: e.data.variable64,
      nombreemergencia: e.data.variable65,
      relacionemergencia: e.data.variable66,
      tlfemergencia: e.data.variable67,
      nombreemergencia2: e.data.variable68,
      relacionemergencia2: e.data.variable69,
      tlfemergencia2: e.data.variable70,
      nombreemergencia3: e.data.variable71,
      relacionemergencia3: e.data.variable72,
      tlfemergencia3: e.data.variable73,

      opantecedentes: e.data.variable74,
      antecedentes: e.data.variable75,
      optratamiento: e.data.variable76,
      tratamiento: e.data.variable77,
      tiempoembarazo: e.data.variable78,
      optipoparto: e.data.variable79,
      idiomamaterno: e.data.variable81,
      conductapositiva: e.data.variable82,
      conductanegativa: e.data.variable83,
      opexperiencia: e.data.variable84,
      nombreescuela: e.data.variable85,
      rangotiempo: e.data.variable86,
      atendidopor: e.data.variable87,
      tiempocompartidomadre: e.data.variable88,
      tiempocompartidopadre: e.data.variable89,
      padeceenfermedad: e.data.variable90,
      ophospitalizado: e.data.variable91,
      ocaciones: e.data.variable92,
      opoperado: e.data.variable93,
      operadocausa: e.data.variable94,
      opcondicion: e.data.variable95,
      especifique: e.data.variable96,
      opmedicamento: e.data.variable97,
      especifiquemedicamento: e.data.variable98,
      opvacunacion: e.data.variable99,
      peso: e.data.variable100,
      talla: e.data.variable101,
      sangre: e.data.variable102,

      penicilina: e.data.variable103,
      mariscos: e.data.variable104,
      yodo: e.data.variable105,
      merthiolate: e.data.variable106,
      aspirina: e.data.variable107,
      citrico: e.data.variable108,
      laptosa: e.data.variable109,
      otroa: e.data.variable110,
      otroalergica: e.data.variable111,
      ninguna: e.data.variable112,
      convulsiones: e.data.variable113,
      asma: e.data.variable114,
      desmayo: e.data.variable115,
      diabetes: e.data.variable116,
      rubeola: e.data.variable117,
      papera: e.data.variable118,
      sarampion: e.data.variable119,
      varicela: e.data.variable120,
      tosferina: e.data.variable121,
      hepatitis: e.data.variable122,
      amebiasis: e.data.variable123,
      otroen: e.data.variable124,
      otroenfermedad: e.data.variable125,
      ningunae: e.data.variable126,
      opredessociales: e.data.variable127,
      opevangelio: e.data.variable128,
      oprepresentante: e.data.variable129,
      nombrerecibo: e.data.variable130,
      cedularecibo: e.data.variable131,
      status: e.data.variable133,
      nivel: e.data.variable134,
      idplanillaM: e.data.variable135,
    });

    if (this.state.cursos.length > 0) {
      this.buscargrado(this.state.cursos);
    }

    if (this.state.nana) {
      this.setState({
        botonNanaOn: !this.state.botonNanaOn,
      });
    }
    if (this.state.chofer) {
      this.setState({
        botonChoferOn: !this.state.botonChoferOn,
      });
    }
    if (this.state.otro) {
      this.setState({
        botonOtroOn: !this.state.botonOtroOn,
      });
    }
    if (this.state.autorizado1.length > 0) {
      this.setState({ btnemOn: !this.state.btnemOn });
    }
    if (this.state.autorizado2.length > 0) {
      this.setState({ btnem2On: !this.state.btnem2On });
    }
    if (this.state.nombreemergencia.length > 0) {
      this.setState({ btnemOn: !this.state.btnemOn });
    }
    if (this.state.nombreemergencia2.length > 0) {
      this.setState({ btnem2On: !this.state.btnem2On });
    }
  };

  consulta = (e) => {
    console.log(e);
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data)
          if (res.data.variable136) {
            this.cargarDatos(res);
            this.setState({
              verplanillaOn: true,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual2");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data)
          if (res.data.variable136) {
            this.cargarDatos(res);
            this.setState({
              verplanillaOn: true,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanilla = () => {
    if (
      (this.state.tbusqueda === "1" && this.props.usuario1.anioE !== "") ||
      (this.state.tbusqueda === "2" && this.props.usuario1.anioE !== "") ||
      (this.state.tbusqueda === "3" && this.props.usuario1.anioE !== "")
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.buscarPorRepresentante();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Debe seleccionar un Año escolar y un tipo de busqueda, gracias...",
      });
    }
  };

  buscarNoAprobados = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNoActivosV");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No hay Estudiantes sin Aprobar, gracias...",
          });
        }
      });
  };

  buscarPorNombres = () => {
    let data = new FormData();

    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM:
              "No existe ningún Estudiante en Veranito con ese Nombre, gracias...",
          });
        }
      });
  };

  buscarPorRepresentante = () => {
    let data = new FormData();

    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarRepresentante");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  aprobado = () => {
    if (this.state.cursos.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("idplanillaM", this.state.idplanillaM);
      data.append("boton", "aprobarPlanillaV");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal2.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable1);
          console.log(res.data.variable3);
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Aprobar Planillas",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Aprobar Planillas",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Aprobar Planillas",
        cuerpoM: "Debe Seleccionar un Curso o Grado",
      });
    }
  };

  modificarStatus = async () => {
    if (this.state.status !== "P") {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacion);
      data.append("estatus", this.state.status);
      data.append("boton", "modificarStatus");
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal2.php";
      let response = await axios.post(xurl, data);
      //console.log(response.data.variable3)
      if (response.data.variable1) {
        this.setState({
          status: response.data.variable2,
          mensajealerta: true,
          tituloM: "Menu Busqueda de Planillas",
          cuerpoM: "El Status del alumno fue modificado con exito, gracias...",
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Busqueda de Planillas",
          cuerpoM:
            "Ocurrio un error no pudo modificar el Status verifique, gracias...",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Para Modificar el Status Debe ser un Alumno Activo o Suspendido en este caso el Alumno esta Pendiente por Aprobar, gracias...",
      });
    }
  };

  eliminar = () => {
    let data = new FormData();

    data.append("idplanillaM", this.state.idplanillaM);
    data.append("boton", "eliminarPlanilla");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "El expediente fue eliminado con exito, gracias...",
          });
          this.limpiar();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM:
              "Ocurrio un error no pudo ser eliminado el expediente verifique, gracias...",
          });
        }
      });
  };

  modificar = () => {
    let data = new FormData();

    data.append("idplanillaM", this.state.idplanillaM);
    data.append("nombreAlumno", this.state.nombre);
    data.append("apellidoAlumno", this.state.apellido);
    data.append("cedulaLetra", this.state.opcedula);
    data.append("cedula", this.state.identificacion);
    data.append("edad", this.state.edad);
    data.append("sexo", this.state.opsexo);
    data.append("lnacimiento", this.state.lnacimiento);
    data.append("fnacimiento", this.state.fnacimiento);
    data.append("nacionalidad", this.state.nacionalidad);
    data.append("telefono1", this.state.telefonos);
    data.append("domicilio1", this.state.domicilio);
    data.append("personaVive", this.state.personasviven);

    data.append("nombreMadre", this.state.nombremadre);
    data.append("apellidoMadre", this.state.apellidomadre);
    data.append("cedulaMadreLetra", this.state.opcedulaM);
    data.append("cedulaMadre", this.state.identificacionmadre);
    data.append("profesionMadre", this.state.ocupacionmadre);
    data.append("lugarTrabajoMadre", this.state.ltrabajomadre);
    data.append("telefonoTrabajoMadre", this.state.telefonotrabajomadre);
    data.append("celularMadre", this.state.celularmadre);
    data.append("domicilioMadre", this.state.domiciliomadre);
    data.append("telefonoCasaMadre", this.state.telecasamadre);
    data.append("correoMadre", this.state.correomadre);
    data.append("nacionalidadMadre", this.state.nacionalidadmadre);

    data.append("nombrePadre", this.state.nombrepadre);
    data.append("apellidoPadre", this.state.apellidopadre);
    data.append("cedulaPadreLetra", this.state.opcedulaP);
    data.append("cedulaPadre", this.state.identificacionpadre);
    data.append("profesionPadre", this.state.ocupacionpadre);
    data.append("lugarTrabajoPadre", this.state.ltrabajopadre);
    data.append("telefonoTrabajoPadre", this.state.telefonotrabajopadre);
    data.append("celularPadre", this.state.celularpadre);
    data.append("domicilioPadre", this.state.domiciliopadre);
    data.append("telefonoCasaPadre", this.state.telecasapadre);
    data.append("correoPadre", this.state.correopadre);
    data.append("nacionalidadPadre", this.state.nacionalidadpadre);

    data.append("fechaInicio", this.state.fechainicio);
    data.append("curso", this.state.cursos);
    data.append("grado", this.state.grados);
    data.append("transporte", this.state.transporte);
    data.append("personaEncargada", this.state.personaencargada);
    data.append("telefonoContacto", this.state.telecontacto);

    data.append("autorizadoPorMadre", this.state.madre);
    data.append("autorizadoPorPadre", this.state.padre);
    data.append("autorizadoPorNana", this.state.nana);
    data.append("autorizadoPorChofer", this.state.chofer);
    data.append("autorizadoPorOtro", this.state.otro);
    data.append("nombreNana", this.state.autorizado1);
    data.append("celularNana", this.state.celularperautori1);
    data.append("correoNana", this.state.correoperautori1);
    data.append("nombreChofer", this.state.autorizado2);
    data.append("celularChofer", this.state.celularperautori2);
    data.append("correoChofer", this.state.correoperautori3);
    data.append("nombreOtro", this.state.autorizado3);
    data.append("celularOtro", this.state.celularperautori3);
    data.append("correoOtro", this.state.correoperautori3);

    data.append("personaNoAutorizada", this.state.opnoautorizado);
    data.append("nombreNoAutorizado1", this.state.nombrenoauto1);
    data.append("relacion1", this.state.relacionnoauto1);
    data.append("nombreNoAutorizado2", this.state.nombrenoauto2);
    data.append("relacion2", this.state.relacionnoauto2);
    data.append("nombreNoAutorizado3", this.state.nombrenoauto3);
    data.append("relacion3", this.state.relacionnoauto3);

    data.append("nombreEmergencia1", this.state.nombreemergencia);
    data.append("relacionEmergencia1", this.state.relacionemergencia);
    data.append("telefonoEmergencia1", this.state.tlfemergencia);
    data.append("nombreEmergencia2", this.state.nombreemergencia2);
    data.append("relacionEmergencia2", this.state.relacionemergencia2);
    data.append("telefonoEmergencia2", this.state.tlfemergencia2);
    data.append("nombreEmergencia3", this.state.nombreemergencia3);
    data.append("relacionEmergencia3", this.state.relacionemergencia3);
    data.append("telefonoEmergencia3", this.state.tlfemergencia3);

    data.append("problemaGestacion", this.state.opantecedentes);
    data.append("antecedentes", this.state.antecedentes);
    data.append("recibioTratamiento", this.state.optratamiento);
    data.append("tratamiento", this.state.tratamiento);
    data.append("duracionEmbarazo", this.state.tiempoembarazo);
    data.append("tipoParto", this.state.optipoparto);

    data.append("idiomaMaterno", this.state.idiomamaterno);
    data.append("caracteristicaPositiva", this.state.conductapositiva);
    data.append("caracteristicaNegativa", this.state.conductanegativa);

    // $estudianteHaSufrido='NINGUNA DE LAS ENFERMEDADES MENCIONADAS';

    data.append("primeraExperiencia", this.state.opexperiencia);
    data.append("escuelaAnterior", this.state.nombreescuela);
    data.append("asistioRango", this.state.rangotiempo);
    data.append("atendidoPor", this.state.atendidopor);
    data.append("horasMadre", this.state.tiempocompartidomadre);
    data.append("horasPadre", this.state.tiempocompartidopadre);
    data.append("condicionEspecial", this.state.padeceenfermedad);
    data.append("estudianteHospitalizado", this.state.ophospitalizado);
    data.append("ocaciones", this.state.ocaciones);
    data.append("estudianteOperado", this.state.opoperado);
    data.append("causaOperacion", this.state.operadocausa);
    data.append("estudianteEnfermedad", this.state.opcondicion);
    data.append("especifiqueEnfermedad", this.state.especifique);
    data.append("estudianteMedicamento", this.state.opmedicamento);
    data.append("especifiqueMedicamento", this.state.especifiquemedicamento);
    data.append("vacunacion", this.state.opvacunacion);
    data.append("peso", this.state.peso);
    data.append("talla", this.state.talla);
    data.append("sangre", this.state.sangre);

    data.append("penicilina", this.state.penicilina);
    data.append("mariscos", this.state.mariscos);
    data.append("yodo", this.state.yodo);
    data.append("merthiolate", this.state.merthiolate);
    data.append("aspirina", this.state.aspirina);
    data.append("citrico", this.state.citrico);
    data.append("laptosa", this.state.laptosa);
    data.append("otroa", this.state.otroa);
    data.append("otroalergica", this.state.otroalergica);
    data.append("ninguna", this.state.ninguna);

    data.append("convulsiones", this.state.convulsiones);
    data.append("asma", this.state.asma);
    data.append("desmayo", this.state.desmayo);
    data.append("diabetes", this.state.diabetes);
    data.append("rubeola", this.state.rubeola);
    data.append("papera", this.state.papera);
    data.append("sarampiom", this.state.sarampion);
    data.append("varicela", this.state.varicela);
    data.append("tosferina", this.state.tosferina);
    data.append("hepatitis", this.state.hepatitis);
    data.append("amebiasis", this.state.amebiasis);
    data.append("otroen", this.state.otroen);
    data.append("otroenfermedad", this.state.otroenfermedad);
    data.append("ningunae", this.state.ningunae);

    data.append("redessociales", this.state.opredessociales);
    data.append("evangelio", this.state.opevangelio);
    data.append("representanteEstudiante", this.state.oprepresentante);
    data.append("anombreRecibo", this.state.nombrerecibo);
    data.append("cedulaRecibo", this.state.cedularecibo);

    data.append("boton", "modificar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable1);
        console.log(res.data.variable2);
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "El expediente fue modificado con exito, gracias...",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "Ocurrio un error verifique, gracias...",
          });
        }
      });
  };

  modificarp = () => {
    let data = new FormData();

    data.append("idplanillaM", this.state.idplanillaM);
    data.append("nombreAlumno", this.state.nombre);
    data.append("apellidoAlumno", this.state.apellido);
    data.append("cedulaLetra", this.state.opcedula);
    data.append("cedula", this.state.identificacion);
    data.append("edad", this.state.edad);
    data.append("sexo", this.state.opsexo);
    data.append("lnacimiento", this.state.lnacimiento);
    data.append("fnacimiento", this.state.fnacimiento);
    data.append("nacionalidad", this.state.nacionalidad);
    data.append("telefono1", this.state.telefonos);
    data.append("domicilio1", this.state.domicilio);
    data.append("personaVive", this.state.personasviven);

    data.append("nombreMadre", this.state.nombremadre);
    data.append("apellidoMadre", this.state.apellidomadre);
    data.append("cedulaMadreLetra", this.state.opcedulaM);
    data.append("cedulaMadre", this.state.identificacionmadre);
    data.append("profesionMadre", this.state.ocupacionmadre);
    data.append("lugarTrabajoMadre", this.state.ltrabajomadre);
    data.append("telefonoTrabajoMadre", this.state.telefonotrabajomadre);
    data.append("celularMadre", this.state.celularmadre);
    data.append("domicilioMadre", this.state.domiciliomadre);
    data.append("telefonoCasaMadre", this.state.telecasamadre);
    data.append("correoMadre", this.state.correomadre);
    data.append("nacionalidadMadre", this.state.nacionalidadmadre);

    data.append("nombrePadre", this.state.nombrepadre);
    data.append("apellidoPadre", this.state.apellidopadre);
    data.append("cedulaPadreLetra", this.state.opcedulaP);
    data.append("cedulaPadre", this.state.identificacionpadre);
    data.append("profesionPadre", this.state.ocupacionpadre);
    data.append("lugarTrabajoPadre", this.state.ltrabajopadre);
    data.append("telefonoTrabajoPadre", this.state.telefonotrabajopadre);
    data.append("celularPadre", this.state.celularpadre);
    data.append("domicilioPadre", this.state.domiciliopadre);
    data.append("telefonoCasaPadre", this.state.telecasapadre);
    data.append("correoPadre", this.state.correopadre);
    data.append("nacionalidadPadre", this.state.nacionalidadpadre);

    data.append("fechaInicio", this.state.fechainicio);
    data.append("curso", this.state.cursos);
    data.append("grado", this.state.grados);
    data.append("transporte", this.state.transporte);
    data.append("personaEncargada", this.state.personaencargada);
    data.append("telefonoContacto", this.state.telecontacto);

    data.append("autorizadoPorMadre", this.state.madre);
    data.append("autorizadoPorPadre", this.state.padre);
    data.append("autorizadoPorNana", this.state.nana);
    data.append("autorizadoPorChofer", this.state.chofer);
    data.append("autorizadoPorOtro", this.state.otro);
    data.append("nombreNana", this.state.autorizado1);
    data.append("celularNana", this.state.celularperautori1);
    data.append("correoNana", this.state.correoperautori1);
    data.append("nombreChofer", this.state.autorizado2);
    data.append("celularChofer", this.state.celularperautori2);
    data.append("correoChofer", this.state.correoperautori3);
    data.append("nombreOtro", this.state.autorizado3);
    data.append("celularOtro", this.state.celularperautori3);
    data.append("correoOtro", this.state.correoperautori3);

    data.append("personaNoAutorizada", this.state.opnoautorizado);
    data.append("nombreNoAutorizado1", this.state.nombrenoauto1);
    data.append("relacion1", this.state.relacionnoauto1);
    data.append("nombreNoAutorizado2", this.state.nombrenoauto2);
    data.append("relacion2", this.state.relacionnoauto2);
    data.append("nombreNoAutorizado3", this.state.nombrenoauto3);
    data.append("relacion3", this.state.relacionnoauto3);

    data.append("nombreEmergencia1", this.state.nombreemergencia);
    data.append("relacionEmergencia1", this.state.relacionemergencia);
    data.append("telefonoEmergencia1", this.state.tlfemergencia);
    data.append("nombreEmergencia2", this.state.nombreemergencia2);
    data.append("relacionEmergencia2", this.state.relacionemergencia2);
    data.append("telefonoEmergencia2", this.state.tlfemergencia2);
    data.append("nombreEmergencia3", this.state.nombreemergencia3);
    data.append("relacionEmergencia3", this.state.relacionemergencia3);
    data.append("telefonoEmergencia3", this.state.tlfemergencia3);

    // estos datos van vacios pertenecen a maternal
    data.append("problemaGestacion", "");
    data.append("antecedentes", "");
    data.append("recibioTratamiento", "");
    data.append("tratamiento", "");
    data.append("duracionEmbarazo", "");
    data.append("tipoParto", "");
    data.append("idiomaMaterno", "");
    data.append("caracteristicaPositiva", "");
    data.append("caracteristicaNegativa", "");
    data.append("primeraExperiencia", this.state.opexperiencia);
    data.append("escuelaAnterior", this.state.nombreescuela);
    data.append("asistioRango", "");
    data.append("atendidoPor", "");
    data.append("horasMadre", "");
    data.append("horasPadre", "");

    // hasta aqui

    data.append("condicionEspecial", this.state.padeceenfermedad);
    data.append("estudianteHospitalizado", this.state.ophospitalizado);
    data.append("ocaciones", this.state.ocaciones);
    data.append("estudianteOperado", this.state.opoperado);
    data.append("causaOperacion", this.state.operadocausa);
    data.append("estudianteEnfermedad", this.state.opcondicion);
    data.append("especifiqueEnfermedad", this.state.especifique);
    data.append("estudianteMedicamento", this.state.opmedicamento);
    data.append("especifiqueMedicamento", this.state.especifiquemedicamento);
    data.append("vacunacion", this.state.opvacunacion);
    data.append("peso", this.state.peso);
    data.append("talla", this.state.talla);
    data.append("sangre", this.state.sangre);

    data.append("penicilina", this.state.penicilina);
    data.append("mariscos", this.state.mariscos);
    data.append("yodo", this.state.yodo);
    data.append("merthiolate", this.state.merthiolate);
    data.append("aspirina", this.state.aspirina);
    data.append("citrico", this.state.citrico);
    data.append("laptosa", this.state.laptosa);
    data.append("otroa", this.state.otroa);
    data.append("otroalergica", this.state.otroalergica);
    data.append("ninguna", this.state.ninguna);

    data.append("convulsiones", this.state.convulsiones);
    data.append("asma", this.state.asma);
    data.append("desmayo", this.state.desmayo);
    data.append("diabetes", this.state.diabetes);
    data.append("rubeola", this.state.rubeola);
    data.append("papera", this.state.papera);
    data.append("sarampiom", this.state.sarampion);
    data.append("varicela", this.state.varicela);
    data.append("tosferina", this.state.tosferina);
    data.append("hepatitis", this.state.hepatitis);
    data.append("amebiasis", this.state.amebiasis);
    data.append("otroen", this.state.otroen);
    data.append("otroenfermedad", this.state.otroenfermedad);
    data.append("ningunae", this.state.ningunae);

    data.append("redessociales", this.state.opredessociales);
    data.append("evangelio", this.state.opevangelio);
    data.append("representanteEstudiante", this.state.oprepresentante);
    data.append("anombreRecibo", this.state.nombrerecibo);
    data.append("cedulaRecibo", this.state.cedularecibo);

    data.append("boton", "modificar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable1);
        console.log(res.data.variable2);
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "El expediente fue modificado con exito, gracias...",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "Ocurrio un error verifique, gracias...",
          });
        }
      });
  };

  botonnoautorizado = () => {
    //console.log(this.state.btnemOn)
    if (this.state.btnnoauto1On === "SI") {
      this.setState({ btnnoauto1On: "NO" });
    } else {
      this.setState({ btnnoauto1On: "SI" });
    }
  };

  botonnoautorizado2 = () => {
    //console.log(this.state.btnemOn)
    if (this.state.btnnoauto2On === "SI") {
      this.setState({ btnnoauto2On: "NO" });
    } else {
      this.setState({ btnnoauto2On: "SI" });
    }
  };

  botonemergencia = () => {
    //console.log(this.state.btnemOn)
    if (this.state.btnemOn) {
      this.setState({ btnemOn: false });
    } else {
      this.setState({ btnemOn: true });
    }
  };

  botonemergencia2 = () => {
    //console.log(this.state.btnemOn)
    if (this.state.btnem2On) {
      this.setState({ btnem2On: false });
    } else {
      this.setState({ btnem2On: true });
    }
  };

  vistapreviaPoliticas = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/politicasCumplir.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaAutorizacion = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append(
      "nombreEstudiante",
      this.state.nombre + " " + this.state.apellido
    );
    data.append("gradoid", this.state.cursos);
    data.append("fechaInicio", this.state.fechainicio);
    data.append("evangelio", this.state.opevangelio);
    data.append("redessociales", this.state.opredessociales);

    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
      data.append("nacionalidad", this.state.nacionalidadmadre);
      data.append("cedulaRepresentante", this.state.identificacionmadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
      data.append("nacionalidad", this.state.nacionalidadpadre);
      data.append("cedulaRepresentante", this.state.identificacionpadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/autorizacion1.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaPoliticasp = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/politicasCumplirP.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaContratop = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append(
      "nombreEstudiante",
      this.state.nombre + " " + this.state.apellido
    );
    data.append("cedulaAlumno", this.state.identificacion);
    data.append("gradoid", this.state.cursos);
    data.append("grado", this.state.grados);

    data.append("fechaInicio", this.state.fechainicio);

    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
      data.append("nacionalidad", this.state.nacionalidadmadre);
      data.append("cedulaRepresentante", this.state.identificacionmadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
      data.append("nacionalidad", this.state.nacionalidadpadre);
      data.append("cedulaRepresentante", this.state.identificacionpadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/contratoPrimaria.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaContratoCovidp = () => {
    let data = new FormData();
    data.append(
      "nombreEstudiante",
      this.state.nombre + " " + this.state.apellido
    );
    data.append("gradoid", this.state.cursos);
    data.append("grado", this.state.grados);

    data.append("fechaInicio", this.state.fechainicio);

    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
      data.append("nacionalidad", this.state.nacionalidadmadre);
      data.append("cedulaRepresentante", this.state.identificacionmadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
      data.append("nacionalidad", this.state.nacionalidadpadre);
      data.append("cedulaRepresentante", this.state.identificacionpadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/contratoPrimariaCovid.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaAutorizacionp = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append(
      "nombreEstudiante",
      this.state.nombre + " " + this.state.apellido
    );
    data.append("gradoid", this.state.cursos);
    data.append("fechaInicio", this.state.fechainicio);
    data.append("evangelio", this.state.opevangelio);
    data.append("redessociales", this.state.opredessociales);

    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
      data.append("nacionalidad", this.state.nacionalidadmadre);
      data.append("cedulaRepresentante", this.state.identificacionmadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
      data.append("nacionalidad", this.state.nacionalidadpadre);
      data.append("cedulaRepresentante", this.state.identificacionpadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/autorizacion1.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaContrato = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append(
      "nombreEstudiante",
      this.state.nombre + " " + this.state.apellido
    );
    data.append("cedulaAlumno", this.state.identificacion);
    data.append("gradoid", this.state.cursos);
    data.append("fechaInicio", this.state.fechainicio);

    if (this.state.oprepresentante === "MADRE") {
      data.append("nombreRepresentante", this.state.nombremadre);
      data.append("apellidoRepresentante", this.state.apellidomadre);
      data.append("nacionalidad", this.state.nacionalidadmadre);
      data.append("cedulaRepresentante", this.state.identificacionmadre);
    }
    if (this.state.oprepresentante === "PADRE") {
      data.append("nombreRepresentante", this.state.nombrepadre);
      data.append("apellidoRepresentante", this.state.apellidopadre);
      data.append("nacionalidad", this.state.nacionalidadpadre);
      data.append("cedulaRepresentante", this.state.identificacionpadre);
    }
    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/contratoMaternal.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaInscripcionp = () => {
    let data = new FormData();
    data.append("nombreAlumno", this.state.nombre);
    data.append("apellidoAlumno", this.state.apellido);
    data.append("cedula", this.state.identificacion);
    data.append("cedulaLetra", this.state.opcedula);
    data.append("edad", this.state.edad);
    data.append("sexo", this.state.opsexo);
    data.append("lnacimiento", this.state.lnacimiento);
    data.append("fnacimiento", this.state.fnacimiento);
    data.append("nacionalidad", this.state.nacionalidad);
    data.append("telefono1", this.state.telefonos);
    data.append("domicilio1", this.state.domicilio);
    data.append("personaVive", this.state.personasviven);

    data.append("nombreMadre", this.state.nombremadre);
    data.append("apellidoMadre", this.state.apellidomadre);
    data.append("cedulaMadre", this.state.identificacionmadre);
    data.append("cedulaMadreLetra", this.state.opcedulaM);
    data.append("nacionalidadMadre", this.state.nacionalidadmadre);
    data.append("profesionMadre", this.state.ocupacionmadre);
    data.append("lugarTrabajoMadre", this.state.ltrabajomadre);
    data.append("telefonoTrabajoMadre", this.state.telefonotrabajomadre);
    data.append("celularMadre", this.state.celularmadre);
    data.append("domicilioMadre", this.state.domiciliomadre);
    data.append("telefonoCasaMadre", this.state.telecasamadre);
    data.append("correoMadre", this.state.correomadre);

    data.append("nombrePadre", this.state.nombrepadre);
    data.append("apellidoPadre", this.state.apellidopadre);
    data.append("cedulaPadre", this.state.identificacionpadre);
    data.append("cedulaPadreLetra", this.state.opcedulaP);
    data.append("nacionalidadPadre", this.state.nacionalidadpadre);
    data.append("profesionPadre", this.state.ocupacionpadre);
    data.append("lugarTrabajoPadre", this.state.ltrabajopadre);
    data.append("telefonoTrabajoPadre", this.state.telefonotrabajopadre);
    data.append("celularPadre", this.state.celularpadre);
    data.append("domicilioPadre", this.state.domiciliopadre);
    data.append("telefonoCasaPadre", this.state.telecasapadre);
    data.append("correoPadre", this.state.correopadre);

    data.append("fechaInicio", this.state.fechainicio);
    data.append("curso", this.state.cursos);
    data.append("transporte", this.state.transporte);
    data.append("personaEncargada", this.state.personaencargada);
    data.append("telefonoContacto", this.state.telecontacto);
    let auto = "";
    if (this.state.madre) auto = "MADRE, ";
    if (this.state.padre) auto += "PADRE, ";
    if (this.state.nana) auto += "NANA, ";
    if (this.state.chofer) auto += "CHOFER, ";
    if (this.state.otro) auto += "OTRO, ";
    data.append("autorizadoPor", auto);
    data.append("nombreNana", this.state.autorizado1);
    data.append("celularNana", this.state.celularperautori1);
    data.append("correoNana", this.state.correoperautori1);
    data.append("nombreChofer", this.state.autorizado2);
    data.append("celularChofer", this.state.celularperautori2);
    data.append("correoChofer", this.state.correoperautori2);
    data.append("nombreOtro", this.state.autorizado3);
    data.append("celularOtro", this.state.celularperautori3);
    data.append("correoOtro", this.state.correoperautori3);

    data.append("nombreNoAutorizado1", this.state.nombrenoauto1);
    data.append("relacion1", this.state.relacionnoauto1);
    data.append("nombreNoAutorizado2", this.state.nombrenoauto2);
    data.append("relacion2", this.state.relacionnoauto2);
    data.append("nombreNoAutorizado3", this.state.nombrenoauto3);
    data.append("relacion3", this.state.relacionnoauto3);

    data.append("nombreEmergencia1", this.state.nombreemergencia);
    data.append("relacionEmergencia1", this.state.relacionemergencia);
    data.append("telefonoEmergencia1", this.state.tlfemergencia);
    data.append("nombreEmergencia2", this.state.nombreemergencia2);
    data.append("relacionEmergencia2", this.state.relacionemergencia2);
    data.append("telefonoEmergencia2", this.state.tlfemergencia2);
    data.append("nombreEmergencia3", this.state.nombreemergencia3);
    data.append("relacionEmergencia3", this.state.relacionemergencia3);
    data.append("telefonoEmergencia3", this.state.tlfemergencia3);

    data.append("condicionEspecial", this.state.padeceenfermedad);
    data.append("estudianteHospitalizado", this.state.ophospitalizado);
    data.append("ocaciones", this.state.ocaciones);
    data.append("estudianteOperado", this.state.opoperado);
    data.append("causaOperacion", this.state.operadocausa);
    data.append("estudianteEnfermedad", this.state.opcondicion);
    data.append("especifiqueEnfermedad", this.state.especifique);
    data.append("estudianteMedicamento", this.state.opmedicamento);
    data.append("especifiqueMedicamento", this.state.especifiquemedicamento);
    data.append("vacunacion", this.state.opvacunacion);
    data.append("peso", this.state.peso);
    data.append("talla", this.state.talla);
    data.append("sangre", this.state.sangre);
    let alergico = "";
    if (this.state.ninguna) {
      alergico = "NO ES ALERGICO. ";
    } else {
      if (this.state.penicilina) alergico = "PENICILINA, ";
      if (this.state.mariscos) alergico += "MARISCOS, ";
      if (this.state.yodo) alergico += "YODO, ";
      if (this.state.merthiolate) alergico += "MERTHIOLATE, ";
      if (this.state.aspirina) alergico += "ASPIRINA, ";
      if (this.state.citrico) alergico += "CITRICO, ";
      if (this.state.laptosa) alergico += "LAPTOSA, ";
      if (this.state.otroa) alergico += this.state.otroalergica + "";
      alergico += ".";
    }
    data.append("estudianteAlergico", alergico);
    let enfermedad = "";
    if (this.state.ningunae) {
      enfermedad = "NINGUNA DE LAS ENFERMEDADES MENCIONADAS. ";
    } else {
      if (this.state.convulsiones) enfermedad = "CONVULSIONES, ";
      if (this.state.asma) enfermedad += "ASMA, ";
      if (this.state.desmayo) enfermedad += "DESMAYO, ";
      if (this.state.diabetes) enfermedad += "DIABETES, ";
      if (this.state.rubeola) enfermedad += "RUBEOLA, ";
      if (this.state.papera) enfermedad += "PAPERA, ";
      if (this.state.sarampion) enfermedad += "SARAMPION, ";
      if (this.state.varicela) enfermedad += "VARICELA, ";
      if (this.state.tosferina) enfermedad += "TOSFERINA, ";
      if (this.state.hepatitis) enfermedad += "HEPATITIS, ";
      if (this.state.amebiasis) enfermedad += "AMEBIASIS, ";
      if (this.state.otroen) enfermedad += this.state.otroenfermedad;
      enfermedad += ".";
    }
    data.append("estudianteHaSufrido", enfermedad);

    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/inscripcionVeranito.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  vistapreviaInscripcion = () => {
    let data = new FormData();
    data.append("nombreAlumno", this.state.nombre);
    data.append("apellidoAlumno", this.state.apellido);
    data.append("cedula", this.state.identificacion);
    data.append("cedulaLetra", this.state.opcedula);
    data.append("edad", this.state.edad);
    data.append("sexo", this.state.opsexo);
    data.append("lnacimiento", this.state.lnacimiento);
    data.append("fnacimiento", this.state.fnacimiento);
    data.append("nacionalidad", this.state.nacionalidad);
    data.append("telefono1", this.state.telefonos);
    data.append("domicilio1", this.state.domicilio);
    data.append("personaVive", this.state.personasviven);

    data.append("nombreMadre", this.state.nombremadre);
    data.append("apellidoMadre", this.state.apellidomadre);
    data.append("cedulaMadre", this.state.identificacionmadre);
    data.append("cedulaMadreLetra", this.state.opcedulaM);
    data.append("nacionalidadMadre", this.state.nacionalidadmadre);
    data.append("profesionMadre", this.state.ocupacionmadre);
    data.append("lugarTrabajoMadre", this.state.ltrabajomadre);
    data.append("telefonoTrabajoMadre", this.state.telefonotrabajomadre);
    data.append("celularMadre", this.state.celularmadre);
    data.append("domicilioMadre", this.state.domiciliomadre);
    data.append("telefonoCasaMadre", this.state.telecasamadre);
    data.append("correoMadre", this.state.correomadre);

    data.append("nombrePadre", this.state.nombrepadre);
    data.append("apellidoPadre", this.state.apellidopadre);
    data.append("cedulaPadre", this.state.identificacionpadre);
    data.append("cedulaPadreLetra", this.state.opcedulaP);
    data.append("nacionalidadPadre", this.state.nacionalidadpadre);
    data.append("profesionPadre", this.state.ocupacionpadre);
    data.append("lugarTrabajoPadre", this.state.ltrabajopadre);
    data.append("telefonoTrabajoPadre", this.state.telefonotrabajopadre);
    data.append("celularPadre", this.state.celularpadre);
    data.append("domicilioPadre", this.state.domiciliopadre);
    data.append("telefonoCasaPadre", this.state.telecasapadre);
    data.append("correoPadre", this.state.correopadre);

    data.append("fechaInicio", this.state.fechainicio);
    data.append("curso", this.state.cursos);
    data.append("transporte", this.state.transporte);
    data.append("personaEncargada", this.state.personaencargada);
    data.append("telefonoContacto", this.state.telecontacto);
    let auto = "";
    if (this.state.madre) auto = "MADRE, ";
    if (this.state.padre) auto += "PADRE, ";
    if (this.state.nana) auto += "NANA, ";
    if (this.state.chofer) auto += "CHOFER, ";
    if (this.state.otro) auto += "OTRO, ";
    data.append("autorizadoPor", auto);
    data.append("nombreNana", this.state.autorizado1);
    data.append("celularNana", this.state.celularperautori1);
    data.append("correoNana", this.state.correoperautori1);
    data.append("nombreChofer", this.state.autorizado2);
    data.append("celularChofer", this.state.celularperautori2);
    data.append("correoChofer", this.state.correoperautori2);
    data.append("nombreOtro", this.state.autorizado3);
    data.append("celularOtro", this.state.celularperautori3);
    data.append("correoOtro", this.state.correoperautori3);

    data.append("nombreNoAutorizado1", this.state.nombrenoauto1);
    data.append("relacion1", this.state.relacionnoauto1);
    data.append("nombreNoAutorizado2", this.state.nombrenoauto2);
    data.append("relacion2", this.state.relacionnoauto2);
    data.append("nombreNoAutorizado3", this.state.nombrenoauto3);
    data.append("relacion3", this.state.relacionnoauto3);

    data.append("nombreEmergencia1", this.state.nombreemergencia);
    data.append("relacionEmergencia1", this.state.relacionemergencia);
    data.append("telefonoEmergencia1", this.state.tlfemergencia);
    data.append("nombreEmergencia2", this.state.nombreemergencia2);
    data.append("relacionEmergencia2", this.state.relacionemergencia2);
    data.append("telefonoEmergencia2", this.state.tlfemergencia2);
    data.append("nombreEmergencia3", this.state.nombreemergencia3);
    data.append("relacionEmergencia3", this.state.relacionemergencia3);
    data.append("telefonoEmergencia3", this.state.tlfemergencia3);

    data.append("problemaGestacion", this.state.opantecedentes);
    data.append("antecedentes", this.state.antecedentes);
    data.append("recibioTratamiento", this.state.optratamiento);
    data.append("tratamiento", this.state.tratamiento);
    data.append("tipoParto", this.state.optipoparto);
    data.append("duracionEmbarazo", this.state.tiempoembarazo);
    data.append("idiomaMaterno", this.state.idiomamaterno);
    data.append("caracteristicaPositiva", this.state.conductapositiva);
    data.append("caracteristicaNegativa", this.state.conductanegativa);

    // $estudianteHaSufrido='NINGUNA DE LAS ENFERMEDADES MENCIONADAS';

    data.append("primeraExperiencia", this.state.opexperiencia);
    data.append("escuelaAnterior", this.state.nombreescuela);
    data.append("asistioRango", this.state.rangotiempo);
    data.append("atendidoPor", this.state.atendidopor);
    data.append("horasMadre", this.state.tiempocompartidomadre);
    data.append("horasPadre", this.state.tiempocompartidopadre);
    data.append("condicionEspecial", this.state.padeceenfermedad);
    data.append("estudianteHospitalizado", this.state.ophospitalizado);
    data.append("ocaciones", this.state.ocaciones);
    data.append("estudianteOperado", this.state.opoperado);
    data.append("causaOperacion", this.state.operadocausa);
    data.append("estudianteEnfermedad", this.state.opcondicion);
    data.append("especifiqueEnfermedad", this.state.especifique);
    data.append("estudianteMedicamento", this.state.opmedicamento);
    data.append("especifiqueMedicamento", this.state.especifiquemedicamento);
    data.append("vacunacion", this.state.opvacunacion);
    data.append("peso", this.state.peso);
    data.append("talla", this.state.talla);
    data.append("sangre", this.state.sangre);
    let alergico = "";
    if (this.state.ninguna) {
      alergico = "NO ES ALERGICO. ";
    } else {
      if (this.state.penicilina) alergico = "PENICILINA, ";
      if (this.state.mariscos) alergico += "MARISCOS, ";
      if (this.state.yodo) alergico += "YODO, ";
      if (this.state.merthiolate) alergico += "MERTHIOLATE, ";
      if (this.state.aspirina) alergico += "ASPIRINA, ";
      if (this.state.citrico) alergico += "CITRICO, ";
      if (this.state.laptosa) alergico += "LAPTOSA, ";
      if (this.state.otroa) alergico += this.state.otroalergica + "";
      alergico += ".";
    }
    data.append("estudianteAlergico", alergico);
    let enfermedad = "";
    if (this.state.ningunae) {
      enfermedad = "NINGUNA DE LAS ENFERMEDADES MENCIONADAS. ";
    } else {
      if (this.state.convulsiones) enfermedad = "CONVULSIONES, ";
      if (this.state.ASMA) enfermedad += "ASMA, ";
      if (this.state.desmayo) enfermedad += "DESMAYO, ";
      if (this.state.diabetes) enfermedad += "DIABETES, ";
      if (this.state.rubeola) enfermedad += "RUBEOLA, ";
      if (this.state.papera) enfermedad += "PAPERA, ";
      if (this.state.sarampion) enfermedad += "SARAMPION, ";
      if (this.state.varicela) enfermedad += "VARICELA, ";
      if (this.state.tosferina) enfermedad += "TOSFERINA, ";
      if (this.state.hepatitis) enfermedad += "HEPATITIS, ";
      if (this.state.amebiasis) enfermedad += "AMEBIASIS, ";
      if (this.state.otroen) enfermedad += this.state.otroenfermedad;
      enfermedad += ".";
    }
    data.append("estudianteHaSufrido", enfermedad);

    //data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/inscripcionMaternal.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  buscargrado = (e) => {
    //console.log(e);
    let data = new FormData();
    data.append("horario", e);
    data.append("boton", "buscarGrados");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/grado.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xgrado = res.data;
        this.setState({
          igrado: [],
          igrado: xgrado,
        });
      });
  };

  calcularEdad = (e) => {
    //console.log('paso '+e)
    var fecha = "";
    fecha = e;
    if (fecha.length > 0) {
      // Si la fecha es correcta, calculamos la edad
      var values = fecha.split("-");
      var dia = values[2];
      var mes = values[1];
      var ano = values[0];

      // cogemos los valores actuales
      var fecha_hoy = new Date();
      var ahora_ano = fecha_hoy.getYear();
      var ahora_mes = fecha_hoy.getMonth() + 1;
      var ahora_dia = fecha_hoy.getDate();

      // realizamos el calculo
      var edadf = ahora_ano + 1900 - ano;
      if (ahora_mes < mes) {
        edadf--;
      }
      if (mes == ahora_mes && ahora_dia < dia) {
        edadf--;
      }
      if (edadf > 1900) {
        edadf -= 1900;
      }

      this.setState({ edad: edadf });
    } else {
      this.setState({ edad: 0 });
    }
  };

  cursosHorarios = () => {
    axios
      .post(
        "https://institutobilinguelasnaciones.com/backend/maternal.php/?boton=consultarCursos"
      )
      .then((res) => {
        //console.log('pasooooo'+res.data)
        if (res.data) {
          let xcursos = res.data;
          this.setState({
            icursos: xcursos,
          });
        }
      });
  };

  mensajeA = () => {
    if (this.state.mensajealerta) {
      this.setState({
        mensajealerta: false,
        mensaje: "",
      });
    } else {
      this.setState({
        mensajealerta: true,
      });
    }
  };

  componentDidMount() {
    this.cursosHorarios();
    this.buscarAnioEscolar();
  }

  render() {
    return (
      <div className="principal-planillai row">
        <div className="contenedor-planillai col-12">
          <div className="titulo-planillai ">
            {this.state.nivel === "M" && (
              <p>
                CONSULTA DE PLANILLAS PARA PROCESO DE INSCRIPCIÓN MATERNAL.{" "}
              </p>
            )}
            {this.state.nivel === "P" && (
              <p>
                CONSULTA DE PLANILLAS PARA PROCESO DE INSCRIPCIÓN PRIMARIA.{" "}
              </p>
            )}
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              eliminar={this.state.eliplan}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              handleEliminar={this.handleEliminar}
            />
          )}
          {!this.state.verplanillaOn && (
            <div className="contenedorp row">
              <div className="titulo-planillai col-12">
                <p>
                  SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                  CORRESPONDIENTA A SU SELECCIÓN.{" "}
                </p>
              </div>
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="tbusqueda"
                    name="tbusqueda"
                    value={this.state.tbusqueda}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Buscar por --</option>
                    <option value="1">CEDULA/PASAPORTE</option>
                    <option value="2">NOMBRE ESTUDIANTE</option>
                    {/* <option value="3">NOMBRE REPRESENTANTE</option>
                    <option value="4">ALUMNOS NO APROBADOS</option> */}
                  </select>
                </div>
              </div>
              {this.state.tbusqueda !== "4" && (
                <div className="contenedorcedula1 row">
                  <input
                    className="form-control"
                    type="text"
                    name="identificacionp1"
                    id="identificacionp1"
                    autoComplete="off"
                    placeholder={
                      this.state.tbusqueda === "1"
                        ? "Identificación Alumno."
                        : this.state.tbusqueda === "2"
                        ? "Nombre Alumno."
                        : this.state.tbusqueda === "3"
                        ? "Nombre Representante."
                        : ""
                    }
                    maxLength="300"
                    onChange={this.onChange.bind(this)}
                    value={this.state.identificacionp1.toUpperCase()}
                  />
                </div>
              )}
              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarPlanilla.bind(this)}
                >
                  Consultar
                </button>
                <button
                  className="boton"
                  onClick={this.buscarNoAprobados.bind(this)}
                >
                  Listado No Aprobados
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>

              {this.state.listaOn && (
                <div className="cont-tablamedi row">
                  <div className="tablamedi1 col-11">
                    <div className="cont-titulomedi col-12">
                      <span className="titulocasos">
                        Listado de estudiantes
                      </span>
                      <span
                        className="titulo-cerrarcasos"
                        onClick={this.cerrarLista.bind(this)}
                      >
                        X
                      </span>
                    </div>
                    {/* <span className="titulo-casos">Datos del Pago</span> */}
                    <div className="cont-listmedi row">
                      <div className="listmedi col-12">
                        <table className="tablame" border="1">
                          <tbody className="evocuerpo1">
                            {this.state.nalumno.map((item, i) => {
                              return (
                                <tr
                                  className={i % 2 === 0 ? "odd" : ""}
                                  key={item.variable1}
                                  id="tabla"
                                >
                                  <td
                                    className="renglon"
                                    width="10"
                                    onClick={this.consulta.bind(
                                      this,
                                      item.variable1
                                    )}
                                  >
                                    {item.variable2}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {this.state.verplanillaOn && (
            <div className="cont-planillam-principal row">
              <div className="cont-planillam col-12">
                <div className="">
                  {!this.state.btnAceptarOn && (
                    <div className="cont-datos-alumnos col-12">
                      <div className="contenedor-titulo row">
                        <div className="titulo-estudiante col-12">
                          {this.state.status === "A" && (
                            <p>DATOS DEL ESTUDIANTE STATUS - ACTIVO</p>
                          )}
                          {this.state.status === "P" && (
                            <p>DATOS DEL ESTUDIANTE STATUS - PENDIENTE</p>
                          )}
                          {this.state.status === "S" && (
                            <p>DATOS DEL ESTUDIANTE STATUS - SUSPENDIDO</p>
                          )}
                        </div>
                      </div>

                      <div className="fila1-datos-alumno row">
                        <div className="nombres col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="nombre"
                            id="nombre"
                            autoComplete="off"
                            placeholder="Nombres:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.nombre.toUpperCase()}
                          />
                        </div>

                        <div className="apellido col-6">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="apellido"
                            id="apellido"
                            autoComplete="off"
                            placeholder="Apellidos:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.apellido.toUpperCase()}
                          />
                          <span className="tooltip">
                            {" "}
                            Indique el Nombre del Estudiante{" "}
                          </span>
                        </div>
                      </div>
                      <div className="fila2-datos-alumno row">
                        <div className="radio-identificacion col-1">
                          <div className="radio">
                            <label className="container">
                              Cedula
                              <input
                                type="radio"
                                value="CEDULA"
                                name="opcedula"
                                checked={this.state.opcedula === "CEDULA"}
                                onChange={
                                  this.state.status === "P"
                                    ? this.onChange.bind(this)
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="radio">
                            <label className="container">
                              Pasaporte
                              <input
                                type="radio"
                                value="PASAPORTE"
                                name="opcedula"
                                checked={this.state.opcedula === "PASAPORTE"}
                                onChange={
                                  this.state.status === "P"
                                    ? this.onChange.bind(this)
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="Identificacion col-3">
                          {/* <span className="asterisco1">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="identificacion"
                            id="identificacion"
                            autoComplete="off"
                            maxLength="20"
                            required
                            readOnly={
                              this.state.status === "P" ? "" : "readonly"
                            }
                            placeholder="Identificacion:"
                            onChange={this.onChange.bind(this)}
                            value={this.state.identificacion}
                          />
                        </div>
                        {/* <HtmlTooltip title="Seleccione la Fecha de Nacimiento del Estudiante" arrow> */}
                        <div className="fnacimiento col-3">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco2">*</span> */}
                          <input
                            className="form-control"
                            type="date"
                            placeholder="dd/mm/aaaa"
                            pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                            name="fnacimiento"
                            id="fnacimiento"
                            autoComplete="off"
                            required
                            onChange={this.onChange3.bind(this)}
                            value={this.state.fnacimiento}
                          />
                        </div>
                        {/* </HtmlTooltip> */}
                        <div className="edad col-1">
                          {/* <label className="opcion" >Nombres.</label> */}
                          <input
                            className="form-control"
                            type="text"
                            name="edad"
                            id="edad"
                            autoComplete="off"
                            placeholder="Edad:"
                            readOnly="readonly"
                            onChange={this.calcularEdad.bind(this)}
                            value={this.state.edad}
                          />
                        </div>
                        <div className="radio-sexo col-3">
                          {/* <span className="asterisco">*</span> */}
                          {/* <label className="labelsexo">Sexo: </label> */}
                          <label className="container">
                            Masculino
                            <input
                              type="radio"
                              className="opsexoM"
                              name="opsexo"
                              id="opsexo"
                              value="MASCULINO"
                              checked={this.state.opsexo === "MASCULINO"}
                              onChange={this.onChange.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="container">
                            Femenino
                            <input
                              type="radio"
                              className="opsexo"
                              name="opsexo"
                              id="opsexo"
                              value="FEMENINO"
                              checked={this.state.opsexo === "FEMENINO"}
                              onChange={this.onChange.bind(this)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="fila3-datos-alumno row">
                        <div className="lnacimiento col-3">
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="lnacimiento"
                            id="lnacimiento"
                            autoComplete="off"
                            placeholder="Lugar Nacimiento:"
                            maxLength="20"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.lnacimiento.toUpperCase()}
                          />
                        </div>
                        <div className="nacionalidad col-3">
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="nacionalidad"
                            id="nacionalidad"
                            autoComplete="off"
                            placeholder="Nacionalidad:"
                            maxLength="25"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.nacionalidad.toUpperCase()}
                          />
                        </div>
                        <div className="telefonos col-5">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          <input
                            className="form-control"
                            type="text"
                            name="telefonos"
                            id="telefonos"
                            autoComplete="off"
                            placeholder="Telefonos:"
                            maxLength="30"
                            onChange={this.onChange.bind(this)}
                            value={this.state.telefonos.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila4-datos-alumno row">
                        <div className="domicilio col-12">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="domicilio"
                            id="domicilio"
                            autoComplete="off"
                            placeholder="Domicilio:"
                            maxLength="70"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.domicilio.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila5-datos-alumno row">
                        <div className="personasviven col-12">
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="personasviven"
                            id="personasviven"
                            autoComplete="off"
                            placeholder="Personas con las que vive:"
                            maxLength="60"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.personasviven.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="contenedor-titulo row">
                        <div className="titulo-madre col-12">
                          <p>DATOS DE LA MADRE</p>
                        </div>
                      </div>

                      <div className="fila1-datos-madre row">
                        <div className="nombremadre col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="nombremadre"
                            id="nombremadre"
                            autoComplete="off"
                            placeholder="Nombres:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.nombremadre.toUpperCase()}
                          />
                        </div>
                        <div className="apellidomadre col-6">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="apellidomadre"
                            id="apellidomadre"
                            autoComplete="off"
                            placeholder="Apellidos:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.apellidomadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila2-datos-madre row">
                        <div className="radio-identificacion col-1">
                          <div className="radio">
                            <label className="container">
                              Cedula
                              <input
                                type="radio"
                                value="CEDULA"
                                name="opcedulaM"
                                checked={this.state.opcedulaM === "CEDULA"}
                                onChange={
                                  this.state.status === "P"
                                    ? this.onChange.bind(this)
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="radio">
                            <label className="container">
                              Pasaporte
                              <input
                                type="radio"
                                value="PASAPORTE"
                                name="opcedulaM"
                                checked={this.state.opcedulaM === "PASAPORTE"}
                                onChange={
                                  this.state.status === "P"
                                    ? this.onChange.bind(this)
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="Identificacionmadre col-4">
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="identificacionmadre"
                            id="identificacionmadre"
                            autoComplete="off"
                            placeholder="Identificacion:"
                            maxLength="30"
                            required
                            readOnly={
                              this.state.status === "P" ? "" : "readonly"
                            }
                            onChange={this.onChange.bind(this)}
                            value={this.state.identificacionmadre}
                          />
                        </div>
                        <div className="ocupacionmadre col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="ocupacionmadre"
                            id="ocupacionmadre"
                            autoComplete="off"
                            placeholder="Ocupación/Profesión:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.ocupacionmadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila3-datos-madre row">
                        <div className="ltrabajomadre col-12">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="ltrabajomadre"
                            id="ltrabajomadre"
                            autoComplete="off"
                            placeholder="Lugar de Trabajo:"
                            maxLength="50"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.ltrabajomadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila4-datos-madre row">
                        <div className="telefonotrabajomadre col-6">
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="telefonotrabajomadre"
                            id="telefonotrabajomadre"
                            autoComplete="off"
                            placeholder="Telefono de Trabajo:"
                            maxLength="20"
                            onChange={this.onChange.bind(this)}
                            value={this.state.telefonotrabajomadre.toUpperCase()}
                          />
                        </div>
                        <div className="celularmadre col-6">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="celularmadre"
                            id="celularmadre"
                            autoComplete="off"
                            placeholder="Celular:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.celularmadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila5-datos-madre row">
                        <div className="domiciliomadre col-8">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="domiciliomadre"
                            id="domiciliomadre"
                            autoComplete="off"
                            placeholder="Domicilio:"
                            maxLength="55"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.domiciliomadre.toUpperCase()}
                          />
                        </div>
                        <div className="telecasamadre col-4">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="telecasamadre"
                            id="telecasamadre"
                            autoComplete="off"
                            placeholder="Telefono de Casa:"
                            maxLength="20"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.telecasamadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila6-datos-madre row">
                        <div className="correomadre col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="correomadre"
                            id="correomadre"
                            autoComplete="off"
                            placeholder="Correo:"
                            maxLength="50"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.correomadre.toUpperCase()}
                          />
                        </div>
                        <div className="nacionalidadmadre col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="nacionalidadmadre"
                            id="nacionalidadmadre"
                            autoComplete="off"
                            placeholder="Nacionalidad Principal de la Madre:"
                            maxLength="25"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.nacionalidadmadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="contenedor-titulo row">
                        <div className="titulo-padre col-12">
                          <p>DATOS DEL PADRE</p>
                        </div>
                      </div>
                      <div className="fila1-datos-padre row">
                        <div className="nombrepadre col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="nombrepadre"
                            id="nombrepadre"
                            autoComplete="off"
                            placeholder="Nombres:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.nombrepadre.toUpperCase()}
                          />
                        </div>
                        <div className="apellidopadre col-6">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="apellidopadre"
                            id="apellidopadre"
                            autoComplete="off"
                            placeholder="Apellidos:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.apellidopadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila2-datos-padre row">
                        <div className="radio-identificacion col-1">
                          <div className="radio">
                            <label className="container">
                              Cedula
                              <input
                                type="radio"
                                value="CEDULA"
                                name="opcedulaP"
                                checked={this.state.opcedulaP === "CEDULA"}
                                onChange={
                                  this.state.status === "P"
                                    ? this.onChange.bind(this)
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="radio">
                            <label className="container">
                              Pasaporte
                              <input
                                type="radio"
                                value="PASAPORTE"
                                name="opcedulaP"
                                checked={this.state.opcedulaP === "PASAPORTE"}
                                onChange={
                                  this.state.status === "P"
                                    ? this.onChange.bind(this)
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="Identificacionpadre col-4">
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="identificacionpadre"
                            id="identificacionpadre"
                            autoComplete="off"
                            placeholder="Identificacion:"
                            maxLength="30"
                            required
                            readOnly={
                              this.state.status === "P" ? "" : "readonly"
                            }
                            onChange={this.onChange.bind(this)}
                            value={this.state.identificacionpadre}
                          />
                        </div>
                        <div className="ocupacionpadre col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="ocupacionpadre"
                            id="ocupacionpadre"
                            autoComplete="off"
                            placeholder="Ocupación/Profesión:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.ocupacionpadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila3-datos-padre row">
                        <div className="ltrabajopadre col-12">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="ltrabajopadre"
                            id="ltrabajopadre"
                            autoComplete="off"
                            placeholder="Lugar de Trabajo:"
                            maxLength="50"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.ltrabajopadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila4-datos-padre row">
                        <div className="telefonotrabajopadre col-6">
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="telefonotrabajopadre"
                            id="telefonotrabajopadre"
                            autoComplete="off"
                            placeholder="Telefono de Trabajo:"
                            maxLength="20"
                            onChange={this.onChange.bind(this)}
                            value={this.state.telefonotrabajopadre.toUpperCase()}
                          />
                        </div>
                        <div className="celularpadre col-6">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="celularpadre"
                            id="celularpadre"
                            autoComplete="off"
                            placeholder="Celular:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.celularpadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila5-datos-padre row">
                        <div className="domiciliopadre col-8">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="domiciliopadre"
                            id="domiciliopadre"
                            autoComplete="off"
                            placeholder="Domicilio:"
                            maxLength="55"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.domiciliopadre.toUpperCase()}
                          />
                        </div>
                        <div className="telecasapadre col-4">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="telecasapadre"
                            id="telecasapadre"
                            autoComplete="off"
                            placeholder="Telefono de Casa:"
                            maxLength="20"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.telecasapadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila6-datos-padre row">
                        <div className="correopadre col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="correopadre"
                            id="correopadre"
                            autoComplete="off"
                            placeholder="Correo:"
                            maxLength="50"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.correopadre.toUpperCase()}
                          />
                        </div>
                        <div className="nacionalidadpadre col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="nacionalidadpadre"
                            id="nacionalidadpadre"
                            autoComplete="off"
                            placeholder="Nacionalidad Principal del Padre:"
                            maxLength="25"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.nacionalidadpadre.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="contenedor-titulo row">
                        <div className="titulo-admin col-12">
                          <p>DATOS ADMINISTRATIVOS</p>
                        </div>
                      </div>
                      {this.state.nivel === "C" && (
                        <div>
                          <div className="fila1-datos-admin row">
                            <div className="fechainicio1 col-1">
                              <label className="fini">Fecha Inicio.</label>
                            </div>
                            <div className="fechainicio col-4">
                              {/* <span className="asterisco">*</span> */}
                              {/* <HtmlTooltip title="Dia en que el estudiante inicia" arrow> */}
                              <input
                                className="form-control"
                                type="date"
                                placeholder="dd/mm/aaaa"
                                pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                name="fechainicio"
                                id="fechainicio"
                                autoComplete="off"
                                required
                                onChange={this.onChange.bind(this)}
                                value={this.state.fechainicio}
                              />
                              {/* </HtmlTooltip> */}
                            </div>
                            <div className="nombreescuela col-6">
                              {/* <label className="opcion" >Apellidos.</label> */}
                              <span className="asterisco">*</span>
                              <select
                                id="cursos"
                                name="cursos"
                                value={this.state.cursos}
                                onChange={this.onChange.bind(this)}
                              >
                                <option>
                                  -- Seleccione el Mes del Veranito --
                                </option>
                                <option value="1">ENERO 7am a 12pm</option>
                                <option value="2">ENERO 7am a 6pm</option>
                                <option value="3">FEBRERO 7am a 12pm</option>
                                <option value="4">FEBRERO 7am a 6pm</option>
                                <option value="5">
                                  ENERO y FEBRERO 7am a 12pm
                                </option>
                                <option value="6">
                                  ENERO y FEBRERO 7am a 6pm
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="fila2-datos-admin row">
                            <div className="transporte col-6">
                              {/* <span className="asterisco">*</span> */}
                              <input
                                className="form-control"
                                type="text"
                                name="transporte"
                                id="transporte"
                                autoComplete="off"
                                placeholder="Transporte:"
                                maxLength="30"
                                required
                                onChange={this.onChange.bind(this)}
                                value={this.state.transporte.toUpperCase()}
                              />
                            </div>
                            <div className="personaencargada col-6">
                              {/* <label className="opcion" >Apellidos.</label> */}
                              {/* <span className="asterisco">*</span> */}
                              <input
                                className="form-control"
                                type="text"
                                name="personaencargada"
                                id="personaencargada"
                                autoComplete="off"
                                placeholder="Persona a Cargo:"
                                maxLength="25"
                                required
                                onChange={this.onChange.bind(this)}
                                value={this.state.personaencargada.toUpperCase()}
                              />
                            </div>
                          </div>
                          <div className="fila3-datos-admin row">
                            <div className="telecontacto col-12">
                              {/* <label className="opcion" >Apellidos.</label> */}
                              {/* <span className="asterisco">*</span> */}
                              <input
                                className="form-control"
                                type="text"
                                name="telecontacto"
                                id="telecontacto"
                                autoComplete="off"
                                placeholder="Telefono Contacto:"
                                maxLength="30"
                                required
                                onChange={this.onChange.bind(this)}
                                value={this.state.telecontacto.toUpperCase()}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="contenedor-titulo row">
                        <div className="titulo-autorizacion col-12">
                          <p>DATOS PARA AUTORIZACIÓN DE SALIDA</p>
                        </div>
                      </div>
                      <div className="fila1-datos-auto row">
                        <div className="cont-retirado col-4">
                          <label className="retirado">
                            *El Estudiante podrá se retirado por:{" "}
                          </label>
                        </div>
                        <div className="cont-opcion2 col-1">
                          <label className="opcion">Madre.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="madre"
                            id="madre"
                            value="MADRE"
                            checked={this.state.madre}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion2 col-1">
                          <label className="opcion">Padre.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="padre"
                            id="padre"
                            value="PADRE"
                            checked={this.state.padre}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion2 col-1">
                          <label className="opcion">Nana.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="nana"
                            id="nana"
                            value="NANA"
                            checked={this.state.nana}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion2 col-1">
                          <label className="opcion">Chofer.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="chofer"
                            id="chofer"
                            value="CHOFER"
                            checked={this.state.chofer}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion2 col-1">
                          <label className="opcion">Otro.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="otro"
                            id="otro"
                            value="OTRO"
                            checked={this.state.otro}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                      </div>
                      {this.state.botonNanaOn && (
                        <div className="fila2-datos-auto row">
                          <div className="autorizado1 col-12">
                            {/* <label className="opcion" >Nombres.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="autorizado1"
                              id="autorizado1"
                              autoComplete="off"
                              placeholder="Nombre y Apellido de la Nana"
                              maxLength="50"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.autorizado1.toUpperCase()}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.botonNanaOn && (
                        <div className="fila3-datos-auto row">
                          <div className="celularperautori1 col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="celularperautori1"
                              id="celularperautori1"
                              autoComplete="off"
                              placeholder="Celular:"
                              maxLength="30"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.celularperautori1.toUpperCase()}
                            />
                          </div>

                          <div className="correoperautori1 col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="correoperautori1"
                              id="correoperautori1"
                              autoComplete="off"
                              placeholder="Correo:"
                              maxLength="30"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.correoperautori1.toUpperCase()}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.botonChoferOn && (
                        <div className="fila4-datos-auto row">
                          <div className="autorizado2 col-12">
                            {/* <label className="opcion" >Nombres.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="autorizado2"
                              id="autorizado2"
                              autoComplete="off"
                              placeholder="Por favor Ingrese el Nombre y Apellido del Chofer"
                              maxLength="50"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.autorizado2.toUpperCase()}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.botonChoferOn && (
                        <div className="fila5-datos-auto row">
                          <div className="celularperautori2 col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="celularperautori2"
                              id="celularperautori2"
                              autoComplete="off"
                              placeholder="Celular:"
                              maxLength="30"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.celularperautori2.toUpperCase()}
                            />
                          </div>
                          <div className="correoperautori2 col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="correoperautori2"
                              id="correoperautori2"
                              autoComplete="off"
                              placeholder="Correo:"
                              maxLength="30"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.correoperautori2.toUpperCase()}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.botonOtroOn && (
                        <div className="fila6-datos-auto row">
                          <div className="autorizado3 col-12">
                            {/* <label className="opcion" >Nombres.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="autorizado3"
                              id="autorizado3"
                              autoComplete="off"
                              placeholder="Por favor Ingrese el Nombre y Apellido"
                              maxLength="50"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.autorizado3.toUpperCase()}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.botonOtroOn && (
                        <div className="fila7-datos-auto row">
                          <div className="celularperautori3 col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="celularperautori3"
                              id="celularperautori3"
                              autoComplete="off"
                              placeholder="Celular:"
                              maxLength="30"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.celularperautori3.toUpperCase()}
                            />
                          </div>
                          <div className="correoperautori3 col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="correoperautori3"
                              id="correoperautori3"
                              autoComplete="off"
                              placeholder="Correo:"
                              maxLength="30"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.correoperautori3.toUpperCase()}
                            />
                          </div>
                        </div>
                      )}
                      <div className="contenedor-titulo row">
                        <div className="titulo-notificacion col-12">
                          <p>
                            *RECUERDE NOTIFICAR POR ESCRITO CUANDO EL ESTUDIANTE
                            VAYA A SER RETIRADO POR UNA PERSONA DIFERENTE A LAS
                            SEÑALADAS EN ESTA PLANTILLA. IGUALMENTE DEBE ANEXAR
                            LA IDENTIFICACIÓN, DE LO CONTRARIO NO SE LE
                            PERMITIRÁ LA SALIDA*
                          </p>
                        </div>
                      </div>
                      <div className="contenedor-titulo row">
                        <div className="titulo-noautorizado col-12">
                          <p>
                            PERSONAS NO AUTORIZADAS DE RETIRAR AL ESTUDIANTE (EN
                            CASO DE QUE EXISTA){" "}
                          </p>
                        </div>
                      </div>
                      <div className="radio-noautorizado col-10">
                        {/* <span className="asterisco">*</span> */}
                        <label className="labelnoautorizado">
                          ¿Existe alguna persona que no este autorizada para
                          retirar a el estudiante.?
                        </label>

                        <label>
                          <input
                            type="radio"
                            className="opnoautorizadosi"
                            name="opnoautorizado"
                            id="opnoautorizado"
                            value="SI"
                            checked={this.state.opnoautorizado === "SI"}
                            onChange={this.onChange.bind(this)}
                          />
                          SI
                        </label>
                        <label>
                          <input
                            type="radio"
                            className="opnoautorizadono"
                            name="opnoautorizado"
                            id="opnoautorizado"
                            value="NO"
                            checked={this.state.opnoautorizado === "NO"}
                            onChange={this.onChange.bind(this)}
                          />
                          NO
                        </label>
                      </div>
                      {this.state.opnoautorizado === "SI" && (
                        <div className="fila1-datos-noautorizado row">
                          <div className="nombrenoauto1 col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="nombrenoauto1"
                              id="nombrenoauto1"
                              autoComplete="off"
                              placeholder="Nombre y Apellido:"
                              maxLength="30"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.nombrenoauto1.toUpperCase()}
                            />
                          </div>
                          <div className="relacionnoauto1 col-5">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="relacionnoauto1"
                              id="relacionnoauto1"
                              autoComplete="off"
                              placeholder="Relacion:"
                              maxLength="20"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.relacionnoauto1.toUpperCase()}
                            />
                          </div>
                          <div className="accionesb col-1">
                            {this.state.btnnoauto1On === "SI" ? (
                              <button
                                className="botonem"
                                onClick={this.botonnoautorizado.bind(this)}
                              >
                                -
                              </button>
                            ) : (
                              <button
                                className="botonem"
                                onClick={this.botonnoautorizado.bind(this)}
                              >
                                +
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      {this.state.opnoautorizado === "SI" &&
                        this.state.btnnoauto1On === "SI" && (
                          <div className="fila2-datos-noautorizado row">
                            <div className="nombrenoauto2 col-6">
                              {/* <label className="opcion" >Apellidos.</label> */}
                              {/* <span className="asterisco">*</span> */}
                              <input
                                className="form-control"
                                type="text"
                                name="nombrenoauto2"
                                id="nombrenoauto2"
                                autoComplete="off"
                                placeholder="Nombre y Apellido:"
                                maxLength="30"
                                required
                                onChange={this.onChange.bind(this)}
                                value={this.state.nombrenoauto2.toUpperCase()}
                              />
                            </div>
                            <div className="relacionnoauto2 col-5">
                              {/* <label className="opcion" >Apellidos.</label> */}
                              {/* <span className="asterisco">*</span> */}
                              <input
                                className="form-control"
                                type="text"
                                name="relacionnoauto2"
                                id="relacionnoauto2"
                                autoComplete="off"
                                placeholder="Relacion:"
                                maxLength="20"
                                required
                                onChange={this.onChange.bind(this)}
                                value={this.state.relacionnoauto2.toUpperCase()}
                              />
                            </div>
                            <div className="accionesb col-1">
                              {this.state.btnnoauto2On === "SI" ? (
                                <button
                                  className="botonem"
                                  onClick={this.botonnoautorizado2.bind(this)}
                                >
                                  -
                                </button>
                              ) : (
                                <button
                                  className="botonem"
                                  onClick={this.botonnoautorizado2.bind(this)}
                                >
                                  +
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      {this.state.opnoautorizado === "SI" &&
                        this.state.btnnoauto2On === "SI" && (
                          <div className="fila3-datos-noautorizado row">
                            <div className="nombrenoauto3 col-6">
                              {/* <label className="opcion" >Apellidos.</label> */}
                              {/* <span className="asterisco">*</span> */}
                              <input
                                className="form-control"
                                type="text"
                                name="nombrenoauto3"
                                id="nombrenoauto3"
                                autoComplete="off"
                                placeholder="Nombre y Apellido:"
                                maxLength="30"
                                required
                                onChange={this.onChange.bind(this)}
                                value={this.state.nombrenoauto3.toUpperCase()}
                              />
                            </div>
                            <div className="relacionnoauto3 col-5">
                              {/* <label className="opcion" >Apellidos.</label> */}
                              {/* <span className="asterisco">*</span> */}
                              <input
                                className="form-control"
                                type="text"
                                name="relacionnoauto3"
                                id="relacionnoauto3"
                                autoComplete="off"
                                placeholder="Relacion:"
                                maxLength="20"
                                required
                                onChange={this.onChange.bind(this)}
                                value={this.state.relacionnoauto3.toUpperCase()}
                              />
                            </div>
                          </div>
                        )}
                      <div className="contenedor-titulo row">
                        <div className="titulo-notificacion col-12">
                          <p>
                            POR SEGURIDAD ES IMPORTANTE NOTIFICAR POR ESCRITO
                            CUANDO UN EMPLEADO AUTORIZADO DEJA DE PRESTAR
                            SERVICIOS A LA FAMILIA. IGUALMENTE CUANDO EXISTA
                            MEDIDAS LEGALES PARA IMPEDIR A UNA PERSONA TENER
                            CONTACTO CON EL ESTUDIANTE.
                          </p>
                        </div>
                      </div>
                      <div className="contenedor-titulo row">
                        <div className="titulo-noautorizado col-12">
                          <p>CONTACTOS DE EMERGENCIAS </p>
                        </div>
                      </div>
                      <div className="fila1-datos-emergencia row">
                        <div className="nombreemergencia col-6">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="nombreemergencia"
                            id="nombreemergencia"
                            autoComplete="off"
                            placeholder="Nombre y Apellido:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.nombreemergencia.toUpperCase()}
                          />
                        </div>
                        <div className="relacionemergencia col-6">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="relacionemergencia"
                            id="relacionemergencia"
                            autoComplete="off"
                            placeholder="Relación:"
                            maxLength="20"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.relacionemergencia.toUpperCase()}
                          />
                        </div>
                      </div>
                      <div className="fila2-datos-emergencia row">
                        <div className="tlfemergencia col-10">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="tlfemergencia"
                            id="tlfemergencia"
                            autoComplete="off"
                            placeholder="Teléfonos:"
                            maxLength="70"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.tlfemergencia.toUpperCase()}
                          />
                        </div>
                        <div className="accionesb col-2">
                          {this.state.btnemOn ? (
                            <button
                              className="botonem"
                              onClick={this.botonemergencia.bind(this)}
                            >
                              -
                            </button>
                          ) : (
                            <button
                              className="botonem"
                              onClick={this.botonemergencia.bind(this)}
                            >
                              +
                            </button>
                          )}
                        </div>
                      </div>
                      {this.state.btnemOn && (
                        <div className="fila1-datos-emergencia row">
                          <div className="nombreemergencia col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="nombreemergencia2"
                              id="nombreemergencia2"
                              autoComplete="off"
                              placeholder="Nombre y Apellido:"
                              maxLength="30"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.nombreemergencia2.toUpperCase()}
                            />
                          </div>
                          <div className="relacionemergencia col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="relacionemergencia2"
                              id="relacionemergencia2"
                              autoComplete="off"
                              placeholder="Relación:"
                              maxLength="20"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.relacionemergencia2.toUpperCase()}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.btnemOn && (
                        <div className="fila2-datos-emergencia row">
                          <div className="tlfemergencia col-10">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="tlfemergencia2"
                              id="tlfemergencia2"
                              autoComplete="off"
                              placeholder="Teléfonos:"
                              maxLength="70"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.tlfemergencia2.toUpperCase()}
                            />
                          </div>
                          <div className="accionesb col-2">
                            {this.state.btnem2On ? (
                              <button
                                className="botonem"
                                onClick={this.botonemergencia2.bind(this)}
                              >
                                -
                              </button>
                            ) : (
                              <button
                                className="botonem"
                                onClick={this.botonemergencia2.bind(this)}
                              >
                                +
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      {this.state.btnem2On && (
                        <div className="fila1-datos-emergencia row">
                          <div className="nombreemergencia col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="nombreemergencia3"
                              id="nombreemergencia3"
                              autoComplete="off"
                              placeholder="Nombre y Apellido:"
                              maxLength="30"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.nombreemergencia3.toUpperCase()}
                            />
                          </div>
                          <div className="relacionemergencia col-6">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="relacionemergencia3"
                              id="relacionemergencia3"
                              autoComplete="off"
                              placeholder="Relación:"
                              maxLength="20"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.relacionemergencia3.toUpperCase()}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.btnem2On && (
                        <div className="fila2-datos-emergencia row">
                          <div className="tlfemergencia col-10">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="tlfemergencia3"
                              id="tlfemergencia3"
                              autoComplete="off"
                              placeholder="Teléfonos:"
                              maxLength="70"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.tlfemergencia3.toUpperCase()}
                            />
                          </div>
                        </div>
                      )}
                      <div className="titulo-antecedentesescolar col-12">
                        <p>ANTECEDENTE ESCOLAR DEL ESTUDIANTE </p>
                      </div>
                      <div className="fila10-datos-antecedentesescolar row">
                        <div className="cont-retirado col-2">
                          {/* <span className="asterisco4">*</span> */}
                          <label className="labelalergico">
                            Es alergico a:{" "}
                          </label>
                        </div>
                        <div className="cont-opcion22">
                          <label className="opcion2">Penicilina.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="penicilina"
                            id="penicilina"
                            value="PENICILINA"
                            checked={this.state.penicilina}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion22">
                          <label className="opcion2">Mariscos.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="mariscos"
                            id="mariscos"
                            value="MARISCOS"
                            checked={this.state.mariscos}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion22">
                          <label className="opcion2">Yodo.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="yodo"
                            id="yodo"
                            value="YODO"
                            checked={this.state.yodo}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion22">
                          <label className="opcion2">Merthiolate.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="merthiolate"
                            id="merthiolate"
                            value="MERTHIOLATE"
                            checked={this.state.merthiolate}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion22">
                          <label className="opcion2">Aspirina.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="aspirina"
                            id="aspirina"
                            value="ASPIRINA"
                            checked={this.state.aspirina}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion22">
                          <label className="opcion2">Citrico.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="citrico"
                            id="citrico"
                            value="CITRICO"
                            checked={this.state.citrico}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion22">
                          <label className="opcion2">Lactosa.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="laptosa"
                            id="laptosa"
                            value="LACTOSA"
                            checked={this.state.laptosa}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        <div className="cont-opcion22">
                          <label className="opcion2">Otro.</label>
                          <input
                            className="form-control"
                            type="checkbox"
                            name="otroa"
                            id="otroa"
                            value="OTRO"
                            checked={this.state.otroa}
                            onChange={this.onChange2.bind(this)}
                          />
                        </div>
                        {this.state.otroa && (
                          <div className="otroalergica col-3">
                            {/* <label className="opcion" >Apellidos.</label> */}
                            {/* <span className="asterisco3">*</span> */}
                            <input
                              className="form-control"
                              type="text"
                              name="otroalergica"
                              id="otroalergica"
                              autoComplete="off"
                              placeholder="Otro:"
                              maxLength="12"
                              required
                              onChange={this.onChange.bind(this)}
                              value={this.state.otroalergica.toUpperCase()}
                            />
                          </div>
                        )}
                        {!this.state.otroa && (
                          <div className="cont-opcion22 col-1">
                            <label className="opcion2">Ninguna.</label>
                            <input
                              className="form-control"
                              type="checkbox"
                              name="ninguna"
                              id="ninguna"
                              value="NINGUNA"
                              checked={this.state.ninguna}
                              onChange={this.onChange2.bind(this)}
                            />
                          </div>
                        )}
                      </div>

                      <div className="contenedor-titulo row">
                        <div className="titulo-noautorizado col-12">
                          <p>ACUDIENTE DEL ALUMNO</p>
                        </div>
                      </div>
                      <div className="contenedor-titulo row">
                        <div className="titulo-notificacion col-12">
                          <p>
                            PARA EFECTO DEL ACUDIENTE ES IMPORTANTE TENER EL
                            CONOCIMIENTO QUE EL PADRE QUE SEA ASIGNADO COMO
                            ACUDIENTE DEL ALUMNO, CUALQUIER TIPO DE
                            DOCUMENTACIÓN ADMINISTRATIVA O ACADEMICA SERA
                            DIRIGIDO A SU NOMBRE.
                          </p>
                        </div>
                      </div>
                      <div className="fila1-datos-emergencia row">
                        <div className="representante col-7">
                          {/* <span className="asterisco">*</span> */}
                          <label className="labelantecedentesescolar">
                            Por favor seleccione quien será el acudiente:{" "}
                          </label>
                          <label>
                            <input
                              type="radio"
                              className="oprepresentantesi"
                              name="oprepresentante"
                              id="oprepresentante"
                              value="MADRE"
                              checked={this.state.oprepresentante === "MADRE"}
                              onChange={this.onChange.bind(this)}
                            />
                            Madre
                          </label>
                          <label>
                            <input
                              type="radio"
                              className="oprepresentanteno"
                              name="oprepresentante"
                              id="oprepresentante"
                              value="PADRE"
                              checked={this.state.oprepresentante === "PADRE"}
                              onChange={this.onChange.bind(this)}
                            />
                            Padre
                          </label>
                        </div>
                      </div>
                      <div className="fila1-datos-alumno row">
                        <div className="nombrerecibo col-6">
                          {/* <label className="opcion" >Nombres.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="nombrerecibo"
                            id="nombrerecibo"
                            autoComplete="off"
                            placeholder="Los recibos se emiten a nombre de:"
                            maxLength="50"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.nombrerecibo.toUpperCase()}
                          />
                        </div>
                        <div className="apellido col-6">
                          {/* <label className="opcion" >Apellidos.</label> */}
                          {/* <span className="asterisco">*</span> */}
                          <input
                            className="form-control"
                            type="text"
                            name="cedularecibo"
                            id="cedularecibo"
                            autoComplete="off"
                            placeholder="Cédula o Ruc:"
                            maxLength="30"
                            required
                            onChange={this.onChange.bind(this)}
                            value={this.state.cedularecibo.toUpperCase()}
                          />
                        </div>
                      </div>
                      {this.props.usuario1.nivel === "1" && (
                        <div className="accionesb col-12">
                          <button
                            className="boton"
                            onClick={this.limpiar.bind(this)}
                          >
                            Nueva Consulta
                          </button>
                          <button
                            className="boton"
                            onClick={this.vistapreviaInscripcionp.bind(this)}
                          >
                            Vista Previa Inscripción
                          </button>
                          <Link to="/">
                            <button className="boton">Salir</button>
                          </Link>
                        </div>
                      )}

                      {this.state.nivel === "C" && (
                        <div className="accionesb col-12">
                          <button
                            className={
                              this.props.usuario1.nivel !== "2"
                                ? "boton"
                                : "botonOf"
                            }
                            onClick={
                              this.props.usuario1.nivel !== "2"
                                ? this.aprobado.bind(this)
                                : ""
                            }
                          >
                            Aprobar Inscripción
                          </button>
                          <button
                            className={
                              this.props.usuario1.nivel !== "2"
                                ? "boton"
                                : "botonOf"
                            }
                            onClick={
                              this.props.usuario1.nivel !== "2"
                                ? this.modificarp.bind(this)
                                : ""
                            }
                          >
                            Modificar Inscripción
                          </button>
                          <button
                            className={
                              this.props.usuario1.nivel !== "2"
                                ? "boton"
                                : "botonOf"
                            }
                            onClick={
                              this.props.usuario1.nivel !== "2"
                                ? this.eliminar1.bind(this)
                                : ""
                            }
                          >
                            Eliminar Inscripción
                          </button>
                          <button
                            className="boton"
                            onClick={this.modificarStatus.bind(this)}
                          >
                            Modificar Status
                          </button>
                          <button
                            className="boton"
                            onClick={this.limpiar.bind(this)}
                          >
                            Nueva Consulta
                          </button>
                          <button
                            className="boton"
                            onClick={this.vistapreviaInscripcionp.bind(this)}
                          >
                            Vista Previa Inscripción
                          </button>
                          {/* <button className="boton" onClick={this.vistapreviaContratoCovidp.bind(this)}>Vista Previa Contrato Covid</button> */}
                          <Link to="/">
                            <button className="boton">Salir</button>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(planillaI);
