import React, { Component } from 'react';

//componentes
import CambioMaternalesAca from './cambioMaternalesAcademico'

class Index extends Component {

    render(){
        return(
            <div>
                <CambioMaternalesAca />
            </div>
        );
    }
}
export default Index;